@charset "UTF-8";
/*---------------------------------------------------------
Theme Name: BASE
Theme URI: 
Description: BASE
Author: 
Version: 1.0
---------------------------------------------------------*/
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
@import url("https://fonts.googleapis.com/css?family=Roboto");
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

img {
  vertical-align: top;
  font-size: 0;
  line-height: 0; }

@media only screen and (min-width: 769px) {
  .pconly {
    display: block; }
  .sponly {
    display: none; } }

@media only screen and (max-width: 768px) {
  .sponly {
    display: block; }
  .pconly {
    display: none; } }

* {
  box-sizing: border-box;
  font-family: 'Roboto', -apple-systemX, BlinkMacSystemFont, "Helvetica Neue", "Original Yu Gothic", "Yu Gothic", YuGothic, Verdana, Meiryo, "M+ 1p", sans-serif; }

h1,
h2,
h3,
li,
dt,
dd {
  line-height: 150%; }

p {
  margin-bottom: 16px;
  margin-bottom: 1rem;
  line-height: 180%; }

img {
  max-width: 100%;
  height: auto; }

table {
  border-collapse: collapse;
  width: 100%; }
  table th,
  table td {
    text-align: center;
    padding: 12px 4px;
    border: 1px solid #000;
    font-size: 14px; }
    @media screen and (max-width: 948px) {
      table th,
      table td {
        font-size: 12px; } }

textarea, input[type="text"], input[type="email"] {
  width: 100%;
  border-radius: 3px;
  padding: 10px;
  border: 1px solid #666;
  margin-bottom: 16px;
  margin-bottom: 1rem; }

textarea {
  min-height: 100px; }

select {
  padding: 5px 30px 5px 5px;
  outline: none;
  height: 30px;
  display: inline-block;
  position: relative; }
  select:after {
    content: "▼";
    display: block;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 16px;
    height: 16px;
    color: #fff; }

input[type=submit] {
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important; }

input[type=radio], input[type=checkbox] {
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
  resize: none;
  width: 35px !important;
  height: 22px;
  position: relative;
  border: 1px solid #666;
  border-radius: 11px;
  content: "";
  display: inline-block;
  background: #2A7ED9; }

input[type="checkbox"]:after {
  content: "";
  display: block;
  border-radius: 9px;
  border: 1px solid #666;
  background: #fff;
  position: absolute;
  z-index: 1;
  top: 2px;
  left: 2px;
  width: 14px;
  height: 14px;
  transition: 0.1s linear; }

input[type="checkbox"]:checked {
  background: #2A7ED9; }
  input[type="checkbox"]:checked:after {
    left: 15px; }

input[type="image"] {
  max-width: 100%; }

.bold {
  font-weight: bold; }

.fontjp1 {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Original Yu Gothic", "Yu Gothic", YuGothic, Verdana, Meiryo, "M+ 1p", sans-serif; }

.fontjp2 {
  font-family: Georgia, 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif; }

.marker1 {
  background: linear-gradient(transparent 60%, #ffd000 0%); }

.marker2 {
  display: inline;
  padding: 10px 0px;
  background: #ffd000; }

.marker3 {
  background: linear-gradient(transparent 60%, #8d000f 0%); }

.taC {
  text-align: center; }

.taR {
  text-align: right; }

.taL {
  text-align: left; }

.fcRed {
  color: #8d000f; }

.mt0 {
  margin-top: 0px; }

.mt10 {
  margin-top: 10px; }

.mt20 {
  margin-top: 20px; }

.mt30 {
  margin-top: 30px; }

.mt40 {
  margin-top: 40px; }

.mt50 {
  margin-top: 50px; }

.mt100 {
  margin-top: 100px; }

.mb0 {
  margin-bottom: 0px; }

.mb10 {
  margin-bottom: 10px; }

.mb20 {
  margin-bottom: 20px; }

.mb30 {
  margin-bottom: 30px; }

.mb40 {
  margin-bottom: 40px; }

.mb50 {
  margin-bottom: 50px; }

.mb100 {
  margin-bottom: 100px; }

@media only screen and (min-width: 769px) {
  .p30 {
    padding-top: 30px;
    padding-bottom: 30px; } }

@media only screen and (max-width: 768px) {
  .p30 {
    padding-top: 15px;
    padding-bottom: 15px; } }

@media only screen and (min-width: 769px) {
  .p50 {
    padding-top: 50px;
    padding-bottom: 50px; } }

@media only screen and (max-width: 768px) {
  .p50 {
    padding-top: 25px;
    padding-bottom: 25px; } }

.w30 {
  width: 30% !important;
  margin: 0 auto !important;
  text-align: center; }

.w50 {
  width: 50% !important;
  margin: 0 auto !important;
  text-align: center; }

.w60 {
  width: 60% !important;
  margin: 0 auto !important;
  text-align: center; }

.w70 {
  width: 70% !important;
  margin: 0 auto !important;
  text-align: center; }

.w80 {
  width: 80% !important;
  margin: 0 auto !important;
  text-align: center; }

.w90 {
  width: 90% !important;
  margin: 0 auto !important;
  text-align: center; }

.youtube,
.iframe {
  position: relative;
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  padding-top: 69px;
  padding-bottom: 50%;
  overflow: hidden; }
  .youtube iframe,
  .iframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }

.fsXXL {
  line-height: 125%;
  font-weight: bold; }
  @media only screen and (min-width: 769px) {
    .fsXXL {
      font-size: 50px !important; } }
  @media only screen and (max-width: 768px) {
    .fsXXL {
      font-size: 25px !important; } }

.fsXL {
  line-height: 135%;
  font-weight: bold; }
  @media only screen and (min-width: 769px) {
    .fsXL {
      font-size: 38px !important; } }
  @media only screen and (max-width: 768px) {
    .fsXL {
      font-size: 20px !important; } }

.fsL, #sp-menu ul a {
  line-height: 150%; }
  @media only screen and (min-width: 769px) {
    .fsL, #sp-menu ul a {
      font-size: 30px !important; } }
  @media only screen and (max-width: 768px) {
    .fsL, #sp-menu ul a {
      font-size: 24px !important; } }

@media only screen and (min-width: 769px) {
  .fsM {
    font-size: 20px !important; } }

@media only screen and (max-width: 768px) {
  .fsM {
    font-size: 18px !important; } }

@media only screen and (min-width: 769px) {
  .fsS, .btn {
    font-size: 16px !important; } }

@media only screen and (max-width: 768px) {
  .fsS, .btn {
    font-size: 14px !important; } }

@media only screen and (min-width: 769px) {
  .fsXS, .main.page3 dt,
  .main.page3 dd {
    font-size: 14px !important; } }

@media only screen and (max-width: 768px) {
  .fsXS, .main.page3 dt,
  .main.page3 dd {
    font-size: 14px !important; } }

@media only screen and (min-width: 769px) {
  .fsXXS {
    font-size: 12px !important; } }

@media only screen and (max-width: 768px) {
  .fsXXS {
    font-size: 12px !important; } }

.clearfix:after {
  content: "";
  font-size: 0.1em;
  line-height: 0;
  display: block;
  height: 0px;
  clear: both;
  visibility: hidden;
  overflow: hidden; }

.clearfix {
  display: inline-block; }

* html .clearfix {
  height: 1px; }

.clearfix {
  display: block; }

.flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.flex-center {
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }

.flex-left {
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start; }

.flex-right {
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

.flex-top {
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start; }

.flex-bottom {
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end; }

.flexself_center {
  -webkit-align-self: center;
      -ms-flex-item-align: center;
              -ms-grid-row-align: center;
          align-self: center; }

.flexself_left {
  -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
          align-self: flex-start; }

.flexself_right {
  -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
          align-self: flex-end; }

@media screen and (max-width: 948px) {
  .all {
    max-width: 100%; } }

@media only screen and (max-width: 768px) {
  .all {
    overflow: hidden; } }

.g-nav {
  position: relative; }
  @media only screen and (min-width: 769px) {
    .g-nav {
      background: #ffffff;
      background: rgba(255, 255, 255, .85);
      width: 246px;
      min-height: 600px;
      overflow: scroll;
      position: fixed;
      height: 100vh;
      padding: 40px 20px; } }
  @media only screen and (max-width: 768px) {
    .g-nav {
      position: fixed;
      width: 85%;
      top: 0px;
      left: 0px;
      z-index: 2; } }
  @media only screen and (max-width: 768px) {
    .g-nav .header-1 a.menu-ico {
      position: fixed;
      right: 0px;
      top: 23px; } }
  @media only screen and (min-width: 769px) {
    .g-nav .header-1 a.menu-ico {
      display: none; } }
  .g-nav .header-1 a.menu-ico:before,
  .g-nav .header-1 a.menu-ico:after,
  .g-nav .header-1 a.menu-ico span {
    background: #000; }
  .g-nav .header {
    background: none; }
    @media only screen and (min-width: 769px) {
      .g-nav .header {
        padding-bottom: 40px; }
        .g-nav .header h1 {
          line-height: 100%;
          height: 29px; } }
    @media only screen and (max-width: 768px) {
      .g-nav .header {
        height: 50px;
        background: #ffffff;
        background: rgba(255, 255, 255, .85); }
        .g-nav .header h1 {
          top: 20%;
          width: 148px;
          left: 5%; } }
  @media only screen and (max-width: 768px) {
    .g-nav nav {
      background: #fff;
      width: 100%;
      height: calc(100vh - 50px);
      padding: 5%;
      position: absolute;
      left: -100%;
      transition: all .25s ease; }
      .g-nav nav.on {
        left: 0px;
        transition: all .25s ease; } }
  .g-nav ul {
    padding-top: 30px; }
    @media only screen and (min-width: 769px) {
      .g-nav ul {
        border-top: 1px dotted #000; } }
    .g-nav ul a {
      position: relative;
      text-decoration: none;
      width: 100%;
      height: 45px;
      display: block; }
      @media only screen and (min-width: 769px) {
        .g-nav ul a {
          margin-bottom: 10px; } }
      @media only screen and (max-width: 768px) {
        .g-nav ul a {
          margin-bottom: 5px; } }
      .g-nav ul a:after {
        content: "";
        position: absolute;
        top: calc(50% - 5px);
        right: 5px;
        width: 10px;
        height: 10px;
        background: url(../img/arrow-right-black.svg) no-repeat;
        transition: all .25s ease; }
      .g-nav ul a .en {
        color: #004e9c;
        font-size: 17px;
        transition: all .25s ease; }
      .g-nav ul a .jp {
        color: #000;
        display: block;
        line-height: 100%;
        font-size: 11px; }
      .g-nav ul a:hover .en {
        color: #ff0008;
        transition: all .25s ease; }
      .g-nav ul a:hover:after {
        right: 0px;
        transition: all .25s ease; }
  .g-nav aside {
    position: absolute;
    width: calc(100% - 40px);
    border-top: 1px dotted #000; }
    @media only screen and (min-width: 769px) {
      .g-nav aside {
        padding-top: 25px;
        bottom: 80px; } }
    @media only screen and (max-width: 768px) {
      .g-nav aside {
        padding-top: 20px;
        bottom: 45px; } }
    .g-nav aside p {
      line-height: 140%; }
    .g-nav aside dt,
    .g-nav aside dd {
      display: inline-block; }
    .g-nav aside dt {
      width: 15px; }
  .g-nav .copy {
    position: absolute;
    bottom: 15px;
    font-size: 10px;
    margin-bottom: 0px; }

.main {
  min-height: 100vh; }
  .main.page1 {
    background: url(../img/bg-top.png) no-repeat;
    background-size: cover;
    padding-top: 40px; }
    @media only screen and (max-width: 768px) {
      .main.page1 {
        padding-top: 100px; } }
  .main.page2, .main.page3, .main.page4 {
    background: #e8e8e8; }
    @media only screen and (max-width: 768px) {
      .main.page2, .main.page3, .main.page4 {
        padding-top: 50px; } }
    .main.page2 footer, .main.page3 footer, .main.page4 footer {
      background: #c8c8c8; }
      @media only screen and (min-width: 769px) {
        .main.page2 footer, .main.page3 footer, .main.page4 footer {
          padding: 20px; } }
      @media only screen and (max-width: 768px) {
        .main.page2 footer, .main.page3 footer, .main.page4 footer {
          padding: 15px 0px; }
          .main.page2 footer .logo img, .main.page3 footer .logo img, .main.page4 footer .logo img {
            max-width: 50%; } }
      .main.page2 footer .inner, .main.page3 footer .inner, .main.page4 footer .inner {
        padding: 0px;
        position: relative; }
      .main.page2 footer .gotop, .main.page3 footer .gotop, .main.page4 footer .gotop {
        position: absolute;
        right: 0px;
        top: -48px;
        transition: all .25s ease;
        width: 60px;
        height: 60px;
        display: block;
        background: #000;
        padding: 36px 10px 10px;
        text-align: center;
        color: #fff;
        text-decoration: none;
        font-size: 10px; }
        @media only screen and (max-width: 768px) {
          .main.page2 footer .gotop, .main.page3 footer .gotop, .main.page4 footer .gotop {
            top: -43px; } }
        .main.page2 footer .gotop:before, .main.page3 footer .gotop:before, .main.page4 footer .gotop:before {
          content: "";
          position: absolute;
          top: 16px;
          right: 23px;
          width: 14px;
          height: 8px;
          background: url(../img/arrow-top-white.svg) no-repeat;
          transition: all .25s ease; }
        .main.page2 footer .gotop:hover, .main.page3 footer .gotop:hover, .main.page4 footer .gotop:hover {
          opacity: 0.7;
          transition: all .25s ease; }
          .main.page2 footer .gotop:hover:before, .main.page3 footer .gotop:hover:before, .main.page4 footer .gotop:hover:before {
            top: 11px;
            transition: all .25s ease; }
      .main.page2 footer .copy, .main.page3 footer .copy, .main.page4 footer .copy {
        font-size: 10px;
        margin-bottom: 0px; }
  .main.page2 .subtitle {
    background: url(../img/bg-aboutus.png) no-repeat;
    background-size: cover; }
  .main.page3 .subtitle {
    background: url(../img/bg-company.png) no-repeat;
    background-size: cover; }
  .main.page3 .box2 {
    margin-bottom: 32px;
    margin-bottom: 2rem; }
    .main.page3 .box2 iframe {
      max-width: 100%;
      max-height: 300px; }
  .main.page3 h3 {
    margin-bottom: 16px;
    margin-bottom: 1rem; }
  @media only screen and (min-width: 769px) {
    .main.page3 .box2 {
      width: 48%; }
    .main.page3 .box-left {
      float: left; }
    .main.page3 .box-right {
      float: right; } }
  .main.page3 dt {
    width: 90px;
    float: left;
    background: url(../img/line-dotted.png) repeat-x center right; }
    .main.page3 dt span {
      background: #e8e8e8;
      font-weight: bold;
      padding-right: 10px;
      display: inline-block; }
  .main.page3 dd {
    margin: 0px 0 14px 100px; }
  @media only screen and (min-width: 769px) {
    .main .subtitle {
      height: 200px;
      margin-left: 246px;
      padding: 53px 0 0 80px; }
      .main .subtitle h2 {
        max-width: 80%; } }
  @media only screen and (max-width: 768px) {
    .main .subtitle {
      height: 150px;
      padding: 50px 5%; }
      .main .subtitle h2 img {
        height: 50px;
        width: auto; } }
  @media only screen and (min-width: 769px) {
    .main .inner {
      margin-left: 326px;
      padding: 55px 0px;
      max-width: 960px; } }
  @media only screen and (max-width: 768px) {
    .main .inner {
      width: 90%;
      padding: 30px 0px;
      margin: 0px auto; }
      .main .inner img {
        max-width: 100%; } }
  .main .inner .box {
    margin-bottom: 38.4px;
    margin-bottom: 2.4rem; }
    .main .inner .box h3 {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 19.2px;
      margin-bottom: 1.2rem; }
    @media only screen and (min-width: 769px) {
      .main .inner .box .txt {
        width: 70%;
        float: left; } }
    .main .inner .box .img img {
      width: 100%;
      height: auto; }
    @media only screen and (min-width: 769px) {
      .main .inner .box .img {
        width: 27%;
        float: right; } }

.btn {
  display: block;
  font-weight: bold;
  color: #fff;
  text-align: center;
  padding: 15px;
  text-decoration: none;
  position: relative; }
  @media only screen and (min-width: 769px) {
    .btn {
      width: 300px; } }
  .btn:before {
    content: "";
    position: absolute;
    top: calc(50% - 5px);
    left: 30px;
    width: 10px;
    height: 10px;
    background: url(../img/arrow-right-white.svg) no-repeat;
    transition: all .25s ease; }
  .btn.red {
    background: #ff0008; }
  .btn.blue {
    background: #004e9c; }
  .btn:hover {
    opacity: 0.7;
    transition: all .25s ease; }
    .btn:hover:before {
      content: "";
      position: absolute;
      top: calc(50% - 5px);
      left: 35px;
      transition: all .25s ease; }

.header-1 {
  height: 50px;
  position: relative;
  background: #000; }
  .header-1.fixed {
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 99; }
    .header-1.fixed #gNav {
      top: 49px; }
  .header-1 .logo {
    width: 100%;
    position: absolute;
    left: 15%;
    width: 70%;
    font-weight: bold;
    line-height: 50px;
    color: #fff;
    text-align: center;
    text-decoration: none; }
    .header-1 .logo img {
      width: 100%; }
  .header-1 a.menu-ico {
    height: 46px;
    width: 46px;
    right: 0%;
    margin: -23px 0 0 0px;
    position: absolute;
    top: 50%; }
    .header-1 a.menu-ico:before, .header-1 a.menu-ico:after,
    .header-1 a.menu-ico span {
      transition: all 0.2s ease-in;
      width: 21px;
      height: 2px;
      border-radius: 1px;
      content: "";
      background: #fff;
      display: block;
      left: 12px;
      position: absolute; }
    .header-1 a.menu-ico:before {
      top: 15px; }
    .header-1 a.menu-ico span {
      top: 50%;
      margin-top: -1px; }
    .header-1 a.menu-ico:after {
      bottom: 15px; }
    .header-1 a.menu-ico.on span {
      opacity: 0; }
    .header-1 a.menu-ico.on:before {
      -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
              transform: rotate(45deg);
      top: 50%; }
    .header-1 a.menu-ico.on:after {
      -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
              transform: rotate(-45deg);
      top: 50%; }

#sp-menu {
  position: absolute;
  display: block;
  background: #000000;
  background: rgba(0, 0, 0, .8);
  z-index: 100;
  width: 100%;
  height: calc(100vh - 50px);
  opacity: 0;
  top: -200vh;
  transition: opacity .25s ease,top .1s .25s;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  align-items: center; }
  #sp-menu.fixed {
    position: fixed; }
  #sp-menu.on {
    top: 50px;
    opacity: 1;
    transition: opacity .25s ease; }
  #sp-menu ul {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    align-items: center; }
    #sp-menu ul li {
      width: 100%;
      text-align: center;
      padding: 5%; }
    #sp-menu ul a {
      color: #fff;
      text-decoration: none; }

#popup {
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  transition: -webkit-flex 2s ease;
  transition: flex 2s ease;
  transition: flex 2s ease, -webkit-flex 2s ease, -ms-flex 2s ease; }
  #popup .popup-box {
    width: 300px;
    background: #000000;
    background: rgba(0, 0, 0, .6);
    border-radius: 5px;
    padding: 15px;
    margin: 10px;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative;
    transition: -webkit-flex 2s ease;
    transition: flex 2s ease;
    transition: flex 2s ease, -webkit-flex 2s ease, -ms-flex 2s ease; }
  #popup * {
    color: #fff; }
  #popup p {
    margin: 0px; }

.popup-box {
  position: relative;
  display: none; }
  .popup-box:before {
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
    top: 50%;
    right: 15px;
    width: 21px;
    height: 2px;
    border-radius: 1px;
    content: "";
    background: #fff;
    display: block;
    position: absolute; }
  .popup-box:after {
    -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: 50%;
    right: 15px;
    width: 21px;
    height: 2px;
    border-radius: 1px;
    content: "";
    background: #fff;
    display: block;
    position: absolute; }
